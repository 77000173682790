<template>
  <sdCards headless>
    <div v-if="!isLoading" style="text-align: center">
      <p class="trading__nothing mt-5 mb-15" v-html="i18n.t('accounts.invest.haventAccs')" />
      <router-link to="/trading/public/">
        <sdButton type="success" size="small">
          {{ i18n.t('accounts.invest.invest') }}
        </sdButton>
      </router-link>
    </div>
    <div class="loading-data-trading" v-else>
      <a-spin size="large"></a-spin>
    </div>
  </sdCards>
</template>

<script>
import { defineComponent, reactive } from 'vue';
import { useI18n } from 'vue-i18n';

const EmptyInvests = defineComponent({
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    const i18n = reactive(useI18n());

    return { i18n };
  },
});

export default EmptyInvests;
</script>
<style lang="scss" scoped>
.trading__nothing {
  opacity: 0.7;
}
</style>
